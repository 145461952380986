.light {
  --background: hsl(0, 0%, 90%);
  --blocks: hsl(0, 5%, 81%);
  --screen: hsl(0, 0%, 93%);
  --primary: hsl(45, 7%, 89%);
  --primary-dark: hsl(35, 11%, 61%);
  --primary-text: hsl(60, 10%, 19%);
  --secondary: hsl(25, 98%, 40%);
  --secondary-dark: hsl(25, 99%, 27%);
  --secondary-text: hsl(0, 0%, 100%);
  --tertiary: hsl(185, 42%, 37%);
  --tertiary-dark: hsl(185, 58%, 25%);
  --tertiary-text: hsl(0, 0%, 100%);
  --text: hsl(60, 10%, 19%);
}

.dark {
  --background: hsl(222, 26%, 31%);
  --blocks: hsl(223, 31%, 20%);
  --screen: hsl(224, 36%, 15%);
  --primary: hsl(30, 25%, 89%);
  --primary-dark: hsl(28, 16%, 65%);
  --primary-text: hsl(221, 14%, 31%);
  --secondary: hsl(6, 63%, 50%);
  --secondary-dark: hsl(6, 70%, 34%);
  --secondary-text: hsl(0, 0%, 100%);
  --tertiary: hsl(225, 21%, 49%);
  --tertiary-dark: hsl(224, 28%, 35%);
  --tertiary-text: hsl(0, 0%, 100%);
  --text: hsl(0, 0%, 100%);
}

.saturated {
  --background: hsl(268, 75%, 9%);
  --blocks: hsl(268, 71%, 12%);
  --screen: hsl(268, 71%, 12%);
  --primary: hsl(268, 47%, 21%);
  --primary-dark: hsl(290, 70%, 36%);
  --primary-text: hsl(52, 100%, 62%);
  --secondary: hsl(176, 100%, 44%);
  --secondary-dark: hsl(177, 92%, 70%);
  --secondary-text: hsl(198, 20%, 13%);
  --tertiary: hsl(281, 89%, 26%);
  --tertiary-dark: hsl(285, 91%, 52%);
  --tertiary-text: hsl(0, 0%, 100%);
  --text: hsl(52, 100%, 62%);
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}

* {
  font-family: 'Montserrat', sans-serif;
  color: var(--text);
}
